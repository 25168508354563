.header {
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;

  @media (prefers-color-scheme: dark) {
    background: #252627;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 760px;
    max-width: 100%;
  }
}
